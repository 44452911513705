<template lang="pug">
.payment-profile
  h1 Dados de pagamento

  .subscription-title
    h2 Sua assinatura

    Tippy(
      v-if="showCancelSubscription || showMigrateSubbscription"
      ref="subscriptionMenu"
      trigger="manual"
      placement="bottom-end"
      animation="shift-away-subtle"
      interactive
      :offset="[0, 8]"
    )

      .subscription-config-menu-button(@click="showSubscriptionMenu")
        fa(:icon="['fas', 'gear']")

      template(v-slot:content)

        .subscription-config-menu

          NuxtLink.subscription-config-menu-item(v-if="showMigrateSubbscription", :to="{ name: 'migrate-subscription' }",) Alterar plano

          NuxtLink.subscription-config-menu-item(v-if="showCancelSubscription", :to="{ name: 'cancel-subscription-renewal' }") Cancelar renovação

  SubscriptionStatus(
    :simplified="false",
    :subscription="subscription",
    :invoices="invoices"
  )

  h2 Cartão de crédito

  .credit-card
    CreditCard(ref="creditCardForm", v-model="creditCard", :showInstallmentsOption="false")

    .actions
      Button(label="Atualizar cartão", @click="savePaymentProfile", :loading="savingPaymentProfile", size="large", rounded)
</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { TrackEventOptions } from '@gtm-support/vue-gtm'
import type { CreditCardInformation } from '~/components/input/CreditCard.vue'
import type { VindiTokenizationResponse } from '~/services/vindi/vindiService'

import { Tippy } from 'vue-tippy'

import CreditCard from '~/components/input/CreditCard.vue'

import { maskCardNumber } from '~/scripts/masks/masks'

import CustomerService from '~/services/customer/customerService'
import VindiService from '~/services/vindi/vindiService'

import { useActiveSubscription } from '~/store/subscription/subscription'

import { useGtm } from '@gtm-support/vue-gtm'

import SubscriptionStatus from '../Subscription/SubscriptionStatus.vue'
import InvoiceService from '~/services/invoice/invoiceService'

const vindiCreditCardBrandToIssuer: Record<string, string> = {
  'Visa': 'visa',
  'VISA': 'visa',
  'MasterCard': 'mastercard',
  'MASTERCARD': 'mastercard',
  'ELO': 'elo',
  'Diners Club': 'diners_club',
  'American Express': 'amex',
  'Hipercard': 'hipercard',
}

useHead({
  title: 'Dados de pagamento'
})

definePageMeta({
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
    {
      title: 'Dados de pagamento',
      link: { name: 'edit-payment-profile' },
    },
  ],
})

const config = useConfig()
const gtm = useGtm()
const notifications = useNotifications()

const trackGtmEvent = (options: TrackEventOptions) => {
  try {
    gtm?.trackEvent(options)
  } catch(e) {
    captureException(new Error('Failed to track gtm event', { cause: e}))
  }
}

const activeSubscription = useActiveSubscription()
const subscription = computed(() => activeSubscription.subscription)

const subscriptionMenu = ref<typeof Tippy | null>(null)

const showSubscriptionMenu = () => {
  if (subscriptionMenu.value?.state?.isShown) {
    subscriptionMenu.value?.hide()
  } else {
    subscriptionMenu.value?.show()
  }
}

const showMigrateSubbscription = computed(() => {
  if (!subscription.value) {
    return false
  }

  const primarySubscription = subscription.value

  return (
    primarySubscription &&
    primarySubscription.paymentProfileType == "CREDIT_CARD" &&
    primarySubscription.status == "ACTIVE" &&
    (primarySubscription.planId ==
      "br.com.magenta.vindi.test.basic.monthly.11990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.monthly.11990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.monthly.15990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.monthly.15990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.monthly.16990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.monthly.16990" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.quarterly.34470" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.quarterly.34470" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.test.basic.quarterly.46770" ||
      primarySubscription.planId ==
        "br.com.magenta.vindi.basic.quarterly.46770")
  )
})

const showCancelSubscription = computed(() => {
  if (!subscription.value) {
    return false
  }

  return subscription.value.endAt == null
})

const savingPaymentProfile = ref(false)

const creditCardForm = ref<InstanceType<typeof CreditCard>>()
const creditCard = ref<CreditCardInformation>({
  cardIssuer: '',
  cardNumber: '',
  cardHolderName: '',
  cardExpiration: '',
  cardVerificationCode: '',
  installments: 1,
})

const asyncData = async () => {
  const activeSubscriptionId = activeSubscription.subscription?.id

  const [invoices, paymentProfiles] = await Promise.all([
    activeSubscriptionId ? InvoiceService.findBySubscriptionId(activeSubscriptionId) : Promise.resolve(null),
    CustomerService.findSelfPaymentProfiles(config.billingPlatformId)
  ])

  return { invoices, paymentProfiles }
}
const { data , refresh, pending, error } = await useAsyncData(asyncData)

const invoices = computed(() => data.value?.invoices)
const paymentProfiles = computed(() => data.value?.paymentProfiles)

reportOnError(error, 'Failed to load payment profiles')

const primaryPaymentProfile = computed(() => {
  if (paymentProfiles.value == null) {
    return null
  }

  const subscriptionPaymentProfile = paymentProfiles.value.find((paymentProfile) => subscription.value?.paymentProfileId == paymentProfile.id)
  if (subscriptionPaymentProfile != null) {
    return subscriptionPaymentProfile
  }

  return paymentProfiles.value.sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0]
})

watch(() => primaryPaymentProfile.value, (newValue, oldValue) => {
  if (!oldValue && newValue) {
    if (newValue.creditCard) {
      creditCard.value = {
        cardIssuer: vindiCreditCardBrandToIssuer[newValue.creditCard.brand],
        cardNumber: maskCardNumber(newValue.creditCard.displayNumber.replaceAll('X', '●'), false),
        cardHolderName: newValue.creditCard.holderName,
        cardExpiration: newValue.creditCard.month.toString().padStart(2, '0') + '/' + newValue.creditCard.year.toString().padStart(4, '0'),
        cardVerificationCode: '',
        installments: 1
      }
    }
  }
}, { immediate: true })

const savePaymentProfile = async () => {
  if (!await creditCardForm.value!.validate()) {
    return
  }

  const creditCardData = creditCard.value

  if (creditCardData.cardNumber.indexOf('●') >= 0) {
    notifications.error('Por favor, insira um novo número de cartão de crédito')

    return
  }

  savingPaymentProfile.value = true

  try {
    var vindiTokenizationResponse: VindiTokenizationResponse | null = null
    try {
      vindiTokenizationResponse =
        await VindiService.tokenizeCreditCard({
          holderName: creditCardData.cardHolderName,
          cardNumber: creditCardData.cardNumber,
          cardExpiration: creditCardData.cardExpiration,
          cardCVV: creditCardData.cardVerificationCode,
          paymentCompanyCode: creditCardData.cardIssuer
        })

        trackGtmEvent({
          category: "credit_card_verification",
          action: "successful",
          label: "from_registration"
        })
    } catch(error: FetchError | any) {
      trackGtmEvent({
        category: "credit_card_verification",
        action: "failed",
        label: "from_registration"
      })

      if ("status" in error && error.status == 422) {
        notifications.warning("Cartão de crédito inválido. Por favor, verifique os dados do cartão de crédito e tente novamente")

        return
      } else {
        captureException(new Error('Failed to verify credit card', { cause: error}))

        return
      }
    }

    const request = {
      paymentProfileType: 'CREDIT_CARD',
      creditCardToken: vindiTokenizationResponse!.gatewayToken,
    }

    if (primaryPaymentProfile.value == null) {
      // Create
      await VindiService.createSelfPaymentProfile(config.billingPlatformId, request)
    } else {
      // Update
      await VindiService.updatePaymentProfile(config.billingPlatformId, primaryPaymentProfile.value.id, request)
    }

    await refresh()

    notifications.success('Cartão de crédito atualizado com sucesso')
  } catch (error: FetchError | any) {
    captureException(new Error('Failed to update credit card', { cause: error}))

    notifications.error('Não foi possível atualizar o cartão de crédito. Por favor, tente novamente mais tarde')
  } finally {
    savingPaymentProfile.value = false
  }
}
</script>
<style lang="sass" scoped>
@import '@/assets/styles/variables.sass'

.payment-profile
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .subscription-title
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

  .subscription-config-menu-button
    display: inline-block
    cursor: pointer
    font-size: 16px
    padding: 8px
    border-radius: 4px
    color: #333
    width: 32px
    height: 32px

    &:hover
      background: #f5f5f5

  .credit-card

    .actions
      margin: 16px 0
      text-align: center
</style>
<style lang="sass">
@import 'tippy.js/animations/shift-away-subtle.css'

.subscription-config-menu
  background: white
  padding: 8px 0
  display: flex
  flex-direction: column
  gap: 4px
  box-shadow: 0 15px 45px -10px rgba(0, 0, 0, 0.3)

  .subscription-config-menu-item
    padding: 8px 16px
    cursor: pointer
    font-size: 14px
    min-width: 180px
    text-align: left

    &:hover
      background: #f5f5f5
</style>
